/* eslint react/jsx-one-expression-per-line: off */

import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { FaExternalLinkAlt, FaPhoneAlt } from 'react-icons/fa';

import { ResultFields, PumaResource } from '.';
import { colors, space } from '../../../style/design-system';
import { formatPhone } from '../../../util/phone';

type ResultProps = {
  className?: string
  result: ResultFields
};

const resultDefaultProps = {
  className: '',
};

const Result = ({ className, result }: ResultProps) => {
  const resource = new PumaResource();
  resource.marshal(result);
  return (
    <article className={cx(className, 'py-6', 'px-4')}>
      <h1 className="capitalize">{`${resource.name.toLowerCase()}`}</h1>
      {resource.city && resource.state && (
        <p>
          {resource.address && (
            <>
              {resource.address}
              <br />
            </>
          )}
          <span className="capitalize">{resource.city.toLowerCase()}</span>, {resource.state} {resource.zipCode}
        </p>
      )}
      {resource.phone && (
        <p>
          <a href={`tel:${resource.phone}`}>
            <span><FaPhoneAlt /></span> <span>{`${formatPhone(resource.phone)}`}</span>
          </a>
        </p>
      )}
      {resource.url && (
        <p>
          <a href={resource.url.replace(' ', '')} target="_blank" rel="noopener noreferrer">
            <span><FaExternalLinkAlt /></span> <span>Website</span>
          </a>
        </p>
      )}
    </article>
  );
};

Result.defaultProps = resultDefaultProps;

export default styled(Result)`
  border: 1px solid ${colors.dev.gray.a()};
  .capitalize {
    text-transform: capitalize;
  }

  a {
    display: flex;
    align-items: flex-end;
    text-transform: none;

    svg {
      margin-right: ${space.c};
      transform: translateY(-3px);
    }
  }

  // a {
  //   background-color: ${colors.umaYellow};
  //   color: ${colors.dev.gray.a()};
  //   display: block;
  // }
`;
