import { ResultFields } from './config';

class PumaResource {
  address?: string;

  city?: string;

  id: string;

  name: string;

  phone?: string;

  placementDriver?: string;

  state ?: string;

  url?: string;

  zipCode?: string;

  marshal(result: ResultFields) {
    this.city = result?.city.raw;
    this.id = result?.id.raw;
    this.name = result?.resource_name.raw;
    this.placementDriver = result?.placement_driver.raw;
    this.url = result.url.raw;
    this.zipCode = result?.zipcode.raw;

    // address
    let address = result?.address.raw;
    if (address.toLowerCase().indexOf('see website') > -1) {
      address = undefined;
    }
    this.address = address;

    // phone
    let phone = result?.strip_phone.raw.replace('.', '');
    phone = phone.replace(/^\+?1/, '');
    this.phone = phone.length === 10 && !Number.isNaN(parseInt(phone, 10)) ? phone : undefined;

    // state
    let state = result?.state.raw;
    if (state.length !== 2) {
      state = undefined;
    }
    this.state = state;
  }
}

export default PumaResource;
