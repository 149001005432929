import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { PumaResource, ResultFields } from '.';
import { colors, space } from '../../../style/design-system';

type AutocompleteResultsProps = {
  autocompletedResults: ResultFields[]
  className?: string
  value: string,
};

const AutocompleteResultsDefaultProps = {
  className: '',
};

const AutocompleteResults = (
  { autocompletedResults, className, value }: AutocompleteResultsProps,
) => (
  <div className={className}>
    <ul className={cx('autocomplete-list')}>
      {autocompletedResults.map((result) => {
        const resource = new PumaResource();
        resource.marshal(result);
        return (
          <li key={resource.id}>
            <Link
              className="autocomplete-link"
              to={`/resources?q=${value}`}>
              {resource.name}
            </Link>
          </li>
        );
      })}
    </ul>
  </div>
);

AutocompleteResults.defaultProps = AutocompleteResultsDefaultProps;

export default styled(AutocompleteResults)`
  position: relative;
  z-index: 1;

  .autocomplete-list {
    background-color: #fff;
    border-radius: 1px;
    box-shadow: 0 0 2px ${colors.dev.gray.a(0.25)}, 0 0 4px ${colors.dev.gray.b(0.5)};
    list-style: none;
    margin: 0;
    padding: ${space.b};
    position: absolute;
    text-align: left;
    top: 5px;
    width: 100%;
  }

  .autocomplete-link {
    border-bottom: 1px solid ${colors.dev.gray.d()};
    display: block;
    padding: ${space.b};
  }
`;
