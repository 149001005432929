/* eslint camelcase: off */

const SiteSearchAPIConnector = require('@elastic/search-ui-site-search-connector').default;

const connector = new SiteSearchAPIConnector({
  documentType: process.env.GATSBY_SITE_SEARCH_DOCUMENT_TYPE,
  engineKey: process.env.GATSBY_SITE_SEARCH_ENGINE_KEY,
});

export type ResultFields = {
  address: { raw?: string }
  city: { raw?: string }
  id: { raw: string }
  placement_driver: { raw?: string }
  resource_name: { raw: string }
  state: { raw?: string }
  strip_phone?: { raw: string }
  url: { raw?: string }
  zipcode: { raw?: string }
};

export type ResourceConfig = {
  alwaysSearchOnInitialLoad: boolean
  apiConnector: object
  autocompleteQuery: object
  searchQuery: { result_fields: ResultFields }
  trackUrlState: boolean
};

const resultFields = {
  address: { raw: '' },
  city: { raw: '' },
  id: { raw: '' },
  placement_driver: { raw: '' },
  resource_name: { raw: '' },
  state: { raw: '' },
  strip_phone: { raw: '' },
  url: { raw: '' },
  zipcode: { raw: '' },
};

const resourceCfg: ResourceConfig = {
  alwaysSearchOnInitialLoad: false,
  apiConnector: connector,
  autocompleteQuery: {
    results: {
      result_fields: resultFields,
      size: 10,
    },
  },
  searchQuery: {
    result_fields: resultFields,
  },
  trackUrlState: true,
};

export default resourceCfg;
