/*
eslint
react/jsx-props-no-spreading: off,
react/require-default-props: off,
*/

import React from 'react';
import styled from 'styled-components';
import { SearchBox } from '@elastic/react-search-ui';

import { colors, space } from '../../../style/design-system';

type SearchBarProps = {
  autocompleteResults?: boolean
  autocompleteView?: Function
  className?: string
  onSelectAutocomplete?: (
    selection: { suggestion: string },
    options: object,
    defaultOnSelectAutocomplete: (selection: { suggestion: string }) => void
  ) => void
  onSubmit?: (searchTerm: string) => void
};

const searchBarDefaultProps = {
  autocompleteResults: true,
  autocompleteView: undefined,
  className: undefined,
};

const SearchBar = ({ className, ...rest }: SearchBarProps) => (
  <div className={className}>
    <SearchBox {...rest} />
  </div>
);

SearchBar.defaultProps = searchBarDefaultProps;

export default styled(SearchBar)`
  .sui-search-box {
    border: 1px solid ${colors.dev.gray.a()};
    display: flex;
    width: 100%;
  }
  .sui-search-box__wrapper {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    flex: 0 0 80%;
    flex-direction: row;
    height: auto;

    input {
      border: 0;
      padding: ${space.e} ${space.c};
      width: 100%;
    }
  }
  input[type="submit"].sui-search-box__submit {
    background: none;
    background-color: ${colors.cpb.yellow};
    border: 1px solid ${colors.dev.gray.a()};
    border-left: 0;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: ${colors.dev.gray.a()};
    flex: 0 0 20%;
    font-size: 1.2rem;
    margin: 0;
  }
  flex: 0 0 auto;
`;
