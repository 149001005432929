import React from 'react';
import { SearchProvider } from '@elastic/react-search-ui';

import resourceCfg from './config';

type ProviderProps = {
  children: React.ReactNode
};

const Provider = ({ children }: ProviderProps) => (
  <SearchProvider config={resourceCfg}>
    {typeof window !== 'undefined' && (
      React.Children.map(children, (child) => child)
    )}
  </SearchProvider>
);

export default Provider;
